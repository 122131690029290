var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "patient" },
    [
      _c("div", { staticClass: "tips" }, [
        _vm._v(
          " 根据《互联网医院管理办法》，请填写真实姓名、性别，年龄，手机号等信息，该信息仅就诊医生可见，请放心填写。 "
        ),
      ]),
      _c("div", [
        _c("div", { staticClass: "field flex pr f-jc-sb f-ai-c" }, [
          _c("span", [_vm._v("姓名")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.name,
                expression: "name",
              },
            ],
            attrs: {
              maxlength: "20",
              type: "text",
              placeholder: "请输入真实姓名",
            },
            domProps: { value: _vm.name },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) return
                  _vm.name = $event.target.value
                },
                _vm.validate,
              ],
            },
          }),
        ]),
        _c("div", { staticClass: "field flex pr f-jc-sb f-ai-c" }, [
          _c("span", [_vm._v("性别")]),
          _c("ul", { staticClass: "flex f-ai-c" }, [
            _c(
              "li",
              {
                class: { active: _vm.gender === 1 },
                on: {
                  click: function ($event) {
                    return _vm.checkGender(1)
                  },
                },
              },
              [_vm._v("男")]
            ),
            _c(
              "li",
              {
                class: { active: _vm.gender !== 1 },
                on: {
                  click: function ($event) {
                    return _vm.checkGender(2)
                  },
                },
              },
              [_vm._v("女")]
            ),
          ]),
        ]),
        _c("div", { staticClass: "field flex pr f-jc-sb f-ai-c" }, [
          _c("span", [_vm._v("年龄")]),
          _c("div", { staticClass: "f-i" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.age,
                  expression: "age",
                },
              ],
              attrs: { type: "number", placeholder: "请输入年龄" },
              domProps: { value: _vm.age },
              on: {
                input: [
                  function ($event) {
                    if ($event.target.composing) return
                    _vm.age = $event.target.value
                  },
                  _vm.validate,
                ],
              },
            }),
            _vm._v("岁 "),
          ]),
        ]),
        _c("div", { staticClass: "field flex pr f-jc-sb f-ai-c" }, [
          _c("span", [_vm._v("手机号")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.mobilePhone,
                expression: "mobilePhone",
              },
            ],
            attrs: { type: "number", placeholder: "请输入手机号码" },
            domProps: { value: _vm.mobilePhone },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) return
                  _vm.mobilePhone = $event.target.value
                },
                _vm.validate,
              ],
            },
          }),
        ]),
        _c("div", { staticClass: "field flex pr f-jc-sb f-ai-c p-h" }, [
          _c("span", [_vm._v("身高")]),
          _c("div", { staticClass: "f-i" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.height,
                  expression: "height",
                },
              ],
              attrs: { type: "number", placeholder: "请输入身高" },
              domProps: { value: _vm.height },
              on: {
                input: [
                  function ($event) {
                    if ($event.target.composing) return
                    _vm.height = $event.target.value
                  },
                  _vm.validate,
                ],
              },
            }),
            _vm._v("CM "),
          ]),
        ]),
        _c("div", { staticClass: "field flex pr f-jc-sb f-ai-c" }, [
          _c("span", [_vm._v("体重")]),
          _c("div", { staticClass: "f-i" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.weight,
                  expression: "weight",
                },
              ],
              attrs: { type: "number", placeholder: "请输入体重" },
              domProps: { value: _vm.weight },
              on: {
                input: [
                  function ($event) {
                    if ($event.target.composing) return
                    _vm.weight = $event.target.value
                  },
                  _vm.validate,
                ],
              },
            }),
            _vm._v("KG "),
          ]),
        ]),
      ]),
      _c("s-button", {
        class: `sub-btn ${_vm.btnDisabled ? "disabled" : ""}`,
        attrs: { btnText: "提交", disabled: _vm.btnDisabled },
        on: { "s-click": _vm.clickHandle },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }